<template>
  <validation-observer
    ref="confirmationSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="certificateSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới điểm ưu tiên, quy đổi' : 'Cập nhật điểm ưu tiên, quy đổi'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="admissionRoundId">
          <template v-slot:label>
            Đợt xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Đợt xét tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionRoundId"
              :options="admissionRounds"
              :reduce="option => option.value"
              @input="readAdmissionForm"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="admissionFormId">
          <template v-slot:label>
            Phương thức xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phương thức xét tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionFormId"
              :options="dataListAdmissionForm"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="scope"
        >
          <template v-slot:label>
            Phạm vi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phạm vi"
            rules="required"
          >
            <v-select
              id="scope"
              v-model="targetItem.scope"
              :options="scopeOptions"
              :reduce="(option) => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="factAdmissionCertificateTypeId">
          <template v-slot:label>
            Loại thành tích học tập
          </template>
            <v-select
              id="factAdmissionCertificateTypeId"
              v-model="targetItem.factAdmissionCertificateTypeId"
              label="label"
              :options="dataListCertificateType"
              :reduce="option => option.value"
            />
        </b-form-group>
        <b-form-group label-for="rankMin">
          <template v-slot:label>
            Hạng giải tối thiểu để áp dụng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hạng giải"
            rules="required"
          >
            <v-select
              id="rankMin"
              v-model="targetItem.rankMin"
              label="label"
              :options="RANK_CERTIFICATE_TYPES"
              :reduce="option => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="priorityScore">
          <template v-slot:label>
            Điểm ưu tiên
          </template>
          <b-form-input
            id="priorityScore"
            v-model="targetItem.priorityScore"
            name="score"
            type="number"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('certificateSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { RANK_CERTIFICATE_TYPES, SCOPE_CERTIFICATES, STATUSES } from '@/const/status'
import { Flag } from '@/const/flag'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'CertificateSave',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      scopeOptions: SCOPE_CERTIFICATES,
      RANK_CERTIFICATE_TYPES,
      targetItem: {
        admissionRoundId: null,
        admissionFormId: null,
        scope: null,
        factAdmissionCertificateTypeId: null,
        rankMin: null,
        priorityScore: null,
        orderNo: 1,
        status: Flag.ACTIVE,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListCertificateType: 'factAdmissionCertificateType/dataListCertificateType',
    }),
    isCreated() {
      return !this.dataSource
    },
    statuses() {
      return STATUSES
    },
  },
  methods: {
    ...mapActions({
      createAdmissionPriorityScores: 'admissionPriorityScore/createAdmissionPriorityScores',
      updateAdmissionPriorityScores: 'admissionPriorityScore/updateAdmissionPriorityScores',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getDataCertificateType: 'factAdmissionCertificateType/getCertificateType',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await Promise.all([
        this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId }),
        this.getDataCertificateType(),
      ])
      if (this.dataSource) {
        this.targetItem = { ...this.dataSource }
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.targetItem.admissionRoundId })
      }
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    onHide() {
      this.$refs
        .confirmationSaveFormRef
        .reset()
      this.targetItem = {
        admissionRoundId: null,
        admissionFormId: null,
        scope: null,
        factAdmissionCertificateTypeId: null,
        rankMin: null,
        priorityScore: null,
        orderNo: 1,
        status: Flag.ACTIVE,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .confirmationSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        const response = this.isCreated
          ? await this.createAdmissionPriorityScores(this.targetItem)
          : await this.updateAdmissionPriorityScores(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('certificateSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        this.isLoading = false
      }
    },
  },
}
</script>
